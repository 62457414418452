import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateBanner } from "../../actions/bannerAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../actions/baseUrl';

class BannerUpdateModal extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            id:this.props.currentRecord.id,
            name: this.props.currentRecord.name,
            errors: {},
            coinImage:"",
            icon: this.props.currentRecord.icon,
        };
    }

    componentWillReceiveProps(nextProps) {
   
        if (nextProps.currentRecord) {
            console.log("nextProps.currentRecord.is_deposit",nextProps.currentRecord)
           
            this.setState({
                id: nextProps.currentRecord.id,
                name: nextProps.currentRecord.name,
                icon: nextProps.currentRecord.icon
            })
            console.log("chain",nextProps.currentRecord.chain_type)
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        console.log("valuefdvfdv",event.target)
        this.setState({ [name]: value, emptyField: false, errMsg: "" });
      };

    onChange = e => {
        if (e.target.id === 'user-update-name') {
            this.setState({ name: e.target.value });
        }
        if (e.target.name === 'currencyImage') {
            var currencyImage = e.target.files[0];
            
            var fileSize = e.target.files[0].size;
            if (fileSize > 1000000) {
                this.setState({ invalidImage: "Maximum 1 MB Image allowed.", userUploadImage: false });
                return false;
            }
            console.log(this.state.invalidImage);
            if (!currencyImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid image jpeg,png,gif.", userUploadImage: false });
                return false;
            }
        
            this.setState({
                coinImage: currencyImage,
            });
        }
       
    };

    onCryptoUpdate = e => {
        e.preventDefault();
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append('id',this.state.id);
        formData.append('currency',this.state.coinImage);
        formData.append('name',this.state.name);
        
        this.props.updateBanner(formData,config);
    };
   
    render() {
        console.log(this.state.is_deposit)
       
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Currency</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCryptoUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Currency Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="user-update-name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>

                                   
                                  
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Currency Image</label>
                                        </div>
                                        <div className="col-md-9">
                                        <img style={{width: "70px"}} src={baseUrl+"/static/currencyImage/"+this.state.icon} />
                                            <input
                                                onChange={this.onChange}
                                                id="currencyImage"
                                                name="currencyImage"
                                                type="file"
                                                error={errors.currencyImage}
                                                className={classnames("form-control", {
                                                    invalid: errors.currencyImage
                                                })}/>
                                            <span className="text-danger">{errors.currencyImage}</span>
                                        </div>
                                    </div>
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BannerUpdateModal.propTypes = {
    updateBanner: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateBanner }
)(withRouter(BannerUpdateModal));
