import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import VerifyDocument1 from "../partials/verify/VerifyDocument1";
import VerifyDocument2 from "../partials/verify/VerifyDocument2";
import VerifyDocument3 from "../partials/verify/VerifyDocument3";
import VerifyDocument4 from "../partials/verify/VerifyDocument4";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import * as myConstList from "./baseUrl";
import VerifyDocument5 from "../partials/verify/VerifyDocument5";
import VerifyPersonalDetails from "../partials/verify/VerifyPersonalDetails";
import VerifyDocumentPassport from "../partials/verify/VerifyDocumentPassport";

const baseUrl = myConstList.baseUrl;

class VerifyKyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      i_image: "",
      i_image2: "",
      i_image3: "",
      selfie_image: "",
      tichekId: this.props.match.params.id,
      disable: false,
    };

    this.getData = this.getData.bind(this);
  }

  getData() {
    console.log("getdata", this.props);
    const { user } = this.props.auth;
    axios
      .post(baseUrl + "/api/User-By-Id-Data", { _id: this.state.tichekId })
      .then((res) => {
        console.log("responseapi", res);
        this.setState({
          records: res.data,
          pi_status: res.pi_status,
          i_image: res.data.i_image,
          i_image2: res.data.i_image2,
          i_image3: res.data.i_image3,
          selfie_image: res.data.selfie_image,
          Image_status1: res.data.identity_status,
          Image_status2: res.data.identity_status2,
          Image_status3: res.data.image3_status,
          Image_status4: res.data.selfie_status,
          document_number: res.data.document_number,
          panNumber: res.data.panNumber,
          doc_type: res.data.doc_type,
          countryCode: res.data.country_code,
        });
      })
      .catch();
  }
  componentDidMount() {
    // if (this.props.auth.user.verifyPending !== undefined && this.props.auth.user.verifyPending !== 1) {
    //     this.props.history.push("/dashboard");
    // }
    this.getData();
  }
  componentWillReceiveProps(nextProps) {
    console.log("props---", nextProps);
    // if (nextProps.auth.user.verifyPending !== undefined && nextProps.auth.user.verifyPending !== 1) {
    //     this.props.history.push("/dashboard");
    // }
    if (
      nextProps.userdata !== undefined &&
      nextProps.userdata.newuser !== undefined &&
      nextProps.userdata.newuser.data !== undefined &&
      nextProps.userdata.newuser.data.message !== undefined &&
      nextProps.userdata.newuser.data.success
    ) {
      toast(nextProps.userdata.newuser.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.getData();
  }

  render() {
    return (
      <>
        <div>
          <Navbar />
          <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <button className="btn btn-link mt-3" id="menu-toggle">
                  <FontAwesomeIcon icon={faList} />
                </button>
                {/* {this.state.i_image !== undefined || this.state.i_image2 !== undefined || this.state.i_image3 !== undefined ||
                                 this.state.selfie_image !== undefined ? */}
                <div className="container">
                  <h4 class="mt-2 man_hadding mb-3">Verify Kyc</h4>
                  <div className="chat_box verify_form admin-kyc-main-outer white_box">
                    <div className="admin-kyc-outer">
                      {/* {this.state.pi_status != 0 &&
                                                <VerifyPersonalDetails record={this.state.records} />
                                            } */}
                      {/* <VerifyDocument5 record={this.state.records} /> */}
                      <div className="aadhaar-outer mb-3">
                        {/* {this.state.document_number && <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="user-detail-inner-main d-flex align-items-center">
                                                            <h5 htmlFor="BankName">{this.state.doc_type == "driving" ? "Driving Licence Number:" : `${this.state.doc_type} Number:`}</h5>
                                                            <p>{this.state.document_number}</p>
                                                        </div>
                                                    </div>
                                                </div>} */}
                        <div className="row">
                          <VerifyDocumentPassport
                            record={this.state.records}
                            disable={this.state.disable}
                          />
                          {/* {console.log("this.state.countryCode", this.state.countryCode, this.state.Image_status1)}
                                                    {this.state.countryCode == "+91" ? this.state.Image_status1 != 0 &&
                                                        <VerifyDocument1 record={this.state.records} /> :
                                                        <VerifyDocumentPassport record={this.state.records} />
                                                    } */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="user-detail-inner-main d-flex align-items-center">
                            <h5 htmlFor="BankName">Pancard Number:</h5>
                            <p>{this.state.panNumber}</p>
                          </div>
                        </div>
                      </div>

                      <div className="pan-outer">
                        <div className="row">
                          <div className="col-md-6">
                            {this.state.Image_status3 != 0 && (
                              <VerifyDocument3 record={this.state.records} />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {this.state.Image_status4 == 0 && (
                              <p>
                                <b>Selfie :</b>Pending
                              </p>
                            )}
                            {this.state.Image_status4 != 0 && (
                              <VerifyDocument4
                                record={this.state.records}
                                disable={this.state.disable}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* : <div className="verifydocfont">KYC Document Not Uploaded</div>} */}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

VerifyKyc.propTypes = {
  auth: PropTypes.object.isRequired,
  userdata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userdata: state.userdata,
  records: state.records,
});

export default connect(mapStateToProps)(VerifyKyc);
