import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import { addRoleData } from "../../actions/roleActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import classnames from "classnames";
import { baseUrl } from "../pages/baseUrl";
class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      amount: "",
      role: "add",
      message: "",
      values: [],
      errors: {},
      type: "password",
      type1: "password",
      type2: "password",
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.user.roleManager !== undefined &&
      this.props.auth.user.roleManager !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.roledata !== undefined &&
      nextProps.roledata.role !== undefined &&
      nextProps.roledata.role.data !== undefined &&
      nextProps.roledata.role.data.message !== undefined &&
      nextProps.roledata.role.data.success
    ) {
      //    this.props.history.push("/transaction");
    }
  }
  getData() {
    axios
      .post(baseUrl + "/api/role-manager-data")
      .then((res) => {
        this.setState({ records: res.data, id: res.data[0].id });
      })
      .catch();
  }
  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));
  handleClick1 = () =>
    this.setState(({ type1 }) => ({
      type1: type1 === "text" ? "password" : "text",
    }));
  handleClick2 = () =>
    this.setState(({ type2 }) => ({
      type2: type2 === "text" ? "password" : "text",
    }));
  onChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value, errMsg: "" });
    if (name === "OldPassword") {
      if (!e.target.value) {
        this.setState({
          errors: {
            OldPassword: "This field is required",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          OldPassword: "",
        },
      });
    }
    if (name === "NewPassword") {
      if (!e.target.value) {
        this.setState({
          errors: {
            NewPassword: "This field is required",
          },
        });
        return false;
      }
      var passw =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%~&^*?-_])[A-Za-z\d#$@~!%^&*?-_]{8,20}$/;
      if (!e.target.value.match(passw)) {
        this.setState({
          errors: {
            NewPassword:
              "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          NewPassword: "",
        },
      });
    }
    if (name === "CPassword") {
      if (!e.target.value) {
        this.setState({
          errors: {
            CPassword: "This field is required",
          },
        });
        return false;
      }
      var passw =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%~&^*?-_])[A-Za-z\d#$@~!%^&*?-_]{8,20}$/;
      if (!e.target.value.match(passw)) {
        this.setState({
          errors: {
            CPassword:
              "Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character",
          },
        });
        return false;
      }
      if (this.state.NewPassword !== e.target.value) {
        this.setState({
          errors: {
            CPassword: "Confirm password does not match",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          CPassword: "",
        },
      });
    }
  };
  onUpdateBalance = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    };

    if (
      !this.state.NewPassword &&
      !this.state.OldPassword &&
      !this.state.CPassword
    ) {
      this.setState({
        errors: {
          CPassword: "This field is require",
          NewPassword: "This field is require",
          OldPassword: "This field is require",
        },
      });
      return false
    }
    axios
      .post(
        baseUrl + "/api/ChangePassword",
        {
          NewPassword: this.state.NewPassword,
          OldPassword: this.state.OldPassword,
          CPassword: this.state.CPassword,
        },
        config
      )
      .then((res) => {
        if (res.data.message) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });

          // this.props.history.push('/transaction');
        }
        if (res.data.email) {
          toast(res.data.email, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              {" "}
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <h4 class="mt-2 man_hadding mb-3">Change Password</h4>
              <div className="in_page row">
                <div className="col-md-8">
                  <div className="man_white_box">
                    <div className=" padding_d">
                      <form
                        noValidate
                        onSubmit={this.onUpdateBalance}
                        id="add-user"
                      >
                        <div className="">
                          <div className="tab-content ">
                            <div className="tab-pane active show" id="details">
                              <div className="row mt-2">
                                <div className="col-md-3">
                                  <label htmlFor="password">
                                    Current Password
                                  </label>
                                </div>
                                <div className="col-md-9 ">
                                  <div className="eye_pass">
                                    <input
                                      onChange={this.onChange}
                                      name="OldPassword"
                                      value={this.state.OldPassword}
                                      type={this.state.type}
                                      className={classnames("form-control", {
                                        invalid: errors.email,
                                      })}
                                    />
                                    <span
                                      className="password__show eye1"
                                      onClick={this.handleClick}
                                    >
                                      {this.state.type === "text" ? (
                                        <i className="las la-eye"></i>
                                      ) : (
                                        <i className="las la-low-vision"></i>
                                      )}
                                    </span>
                                  </div>
                                  <span className="text-danger">
                                    {errors.OldPassword}
                                  </span>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-md-3">
                                  <label htmlFor="amount">New Password</label>
                                </div>
                                <div className="col-md-9 ">
                                  <div className="eye_pass">
                                    <input
                                      onChange={this.onChange}
                                      value={this.state.NewPassword}
                                      name="NewPassword"
                                      type={this.state.type1}
                                      className="form-control"
                                    />
                                    <span
                                      className="password__show eye1"
                                      onClick={this.handleClick1}
                                    >
                                      {this.state.type1 === "text" ? (
                                        <i className="las la-eye"></i>
                                      ) : (
                                        <i className="las la-low-vision"></i>
                                      )}
                                    </span>
                                  </div>
                                  <span className="text-danger">
                                    {errors.NewPassword}
                                  </span>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-md-3">
                                  <label htmlFor="amount">
                                    Confirm Password
                                  </label>
                                </div>
                                <div className="col-md-9 ">
                                  <div className="eye_pass">
                                    <input
                                      onChange={this.onChange}
                                      error={errors.amount}
                                      name="CPassword"
                                      type={this.state.type2}
                                      value={this.state.CPassword}
                                      className={classnames("form-control", {
                                        invalid: errors.amount,
                                      })}
                                    />
                                    <span
                                      className="password__show eye1"
                                      onClick={this.handleClick2}
                                    >
                                      {this.state.type2 === "text" ? (
                                        <i className="las la-eye"></i>
                                      ) : (
                                        <i className="las la-low-vision"></i>
                                      )}
                                    </span>
                                  </div>
                                  <span className="text-danger">
                                    {errors.CPassword}
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <button
                                className="btn btn-primary "
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

PasswordChange.propTypes = {
  addRoleData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  roledata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  roledata: state.roledata,
  errors: state.errors,
});
export default connect(mapStateToProps, { addRoleData })(
  withRouter(PasswordChange)
);
