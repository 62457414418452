import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCrypto } from "../../actions/cryptoActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../actions/baseUrl';

class CryptoUpdateModal extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      id: this.props.currentRecord.id,
      name: this.props.currentRecord.name,
      short_name: this.props.currentRecord.short_name,
      chain: this.props.currentRecord.chain_type,
      url: this.props.currentRecord.coin_tron,
      order_sequence: this.props.currentRecord.order_sequence,
      is_deposit: this.props.currentRecord.is_deposit,
      is_withdrawal: this.props.currentRecord.is_withdrawal,
      is_trade: this.props.currentRecord.is_trade,
      is_show: this.props.currentRecord.is_show,
      is_tab: this.props.currentRecord.is_tab,
      errors: {},
      coinImage: "",
      icon: this.props.currentRecord.icon,
    };
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.currentRecord) {
      console.log("nextProps.currentRecord.is_deposit", nextProps)

      this.setState({
        id: nextProps.currentRecord.id,
        name: nextProps.currentRecord.name,
        short_name: nextProps.currentRecord.short_name,
        chain: nextProps.currentRecord.chain,
        url: nextProps.currentRecord.url,
        order_sequence: nextProps.currentRecord.order_sequence,
        is_deposit: nextProps.currentRecord.is_deposit,
        is_withdrawal: nextProps.currentRecord.is_withdrawal,
        is_trade: nextProps.currentRecord.is_trade,
        is_show: nextProps.currentRecord.is_show,
        is_tab: nextProps.currentRecord.is_tab,
        icon: nextProps.currentRecord.icon
      })
      console.log("chain", nextProps.currentRecord.chain_type)
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

  }

  handleChange = (event) => {
    let { name, value } = event.target;
    console.log("valuefdvfdv", event.target)
    this.setState({ [name]: value, emptyField: false, errMsg: "" });
  };

  onChange = e => {
    if (e.target.id === 'user-update-name') {
      this.setState({ name: e.target.value });
    }
    if (e.target.id === 'user-update-short_name') {
      this.setState({ short_name: e.target.value });
    }
    if (e.target.id === 'user-update-is-deposit') {
      this.setState({ is_deposit: e.target.value });
    }
    if (e.target.id === 'user-update-is_withdrawal') {
      this.setState({ is_withdrawal: e.target.value });
    }
    if (e.target.id === 'user-update-is_show') {
      this.setState({ is_show: e.target.value });
    }
    if (e.target.id === 'user-update-is_tab') {
      this.setState({ is_tab: e.target.value });
    }
    if (e.target.id === 'user-update-is_trade') {
      this.setState({ is_trade: e.target.value });
    }
    if (e.target.id === 'user-update-order_sequence') {
      this.setState({ order_sequence: e.target.value });
    }
    if (e.target.name === 'currencyImage') {
      var currencyImage = e.target.files[0];

      var fileSize = e.target.files[0].size;
      if (fileSize > 1000000) {
        this.setState({ invalidImage: "Maximum 1 MB Image allowed.", userUploadImage: false });
        return false;
      }
      console.log(this.state.invalidImage);
      if (!currencyImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        this.setState({ invalidImage: "Please select valid image jpeg,png,gif.", userUploadImage: false });
        return false;
      }

      this.setState({
        coinImage: currencyImage,
      });
    }

  };

  onCryptoUpdate = e => {
    e.preventDefault();

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const formData = new FormData();
    formData.append('currency', this.state.coinImage);
    formData.append('name', this.state.name);
    formData.append('short_name', this.state.short_name);
    formData.append('chain', this.state.chain);
    formData.append('url', this.state.url);
    formData.append('order_sequence', this.state.order_sequence);
    formData.append('id', this.state.id);
    formData.append('is_deposit', this.state.is_deposit);
    formData.append('is_withdrawal', this.state.is_withdrawal);
    formData.append('is_trade', this.state.is_trade);
    formData.append('is_show', this.state.is_show);
    formData.append('is_tab', this.state.is_tab);
    this.props.updateCrypto(formData, config);
  };

  render() {
    console.log(this.state.is_deposit)

    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Currency</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onCryptoUpdate} id="update-user">
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Currency Name</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.name}
                          id="user-update-name"
                          type="text"
                          error={errors.name}
                          className={classnames("form-control", {
                            invalid: errors.name
                          })} readOnly disabled/>
                        <span className="text-danger">{errors.name}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Currency Short Name</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.short_name}
                          id="user-update-short_name"
                          type="text"
                          error={errors.short_name}
                          className={classnames("form-control", {
                            invalid: errors.short_name
                          })} readOnly disabled/>
                        <span className="text-danger">{errors.short_name}</span>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="chain">Currency Chain Type</label>
                        <select value={this.state.chain} name="chain" onChange={this.handleChange} className="form-control">
                          <option value="">Select</option>
                          <option value="ETH">ETH</option>
                          <option value="BSC">BSC</option>
                          <option value="TRON">TRON</option>
                        </select>
                        {/* <input
                                                onChange={this.onChange}
                                                value={this.state.chain}
                                                id="chain"
                                                name="chain"
                                                type="text"
                                                error={errors.chain}
                                                className={classnames("form-control", {
                                                    invalid: errors.chain
                                                })}/> *
                        <span className="text-danger">{errors.chain}</span>
                      </div>
                    </div>
                    */}
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="url">Currency Chain Url</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.url}
                          id="url"
                          name="url"
                          type="text"
                          error={errors.url}
                          className={classnames("form-control", {
                            invalid: errors.url
                          })} />
                        <span className="text-danger">{errors.url}</span>
                      </div>
                    </div> 
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Currency Image</label>
                        <input
                          onChange={this.onChange}
                          id="currencyImage"
                          name="currencyImage"
                          type="file"
                          error={errors.currencyImage}
                          className={classnames("form-control", {
                            invalid: errors.currencyImage
                          })} />
                        <img style={{ width: "70px" }} src={baseUrl + "/static/currencyImage/" + this.state.icon} />
                        <span className="text-danger">{errors.currencyImage}</span>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Order Sequence</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.order_sequence}
                          id="user-update-order_sequence"
                          type="text"
                          error={errors.order_sequence}
                          className={classnames("form-control", {
                            invalid: errors.order_sequence
                          })} />
                        <span className="text-danger">{errors.order_sequence}</span>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className='col-md-4'>
                      <div className="">
                        <label htmlFor="is_deposit">Deposit</label>
                      </div>
                      <div className="">
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">

                            <input
                              checked={this.state.is_deposit == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_deposit"
                              type="radio" className="form-check-input"
                              id="user-update-is-deposit"
                            />Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_deposit == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_deposit"
                              id="user-update-is-deposit"
                              type="radio" className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>

                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="col-md-12">
                        <label htmlFor="is_withdrawal">Withdrawal</label>
                      </div>
                      <div className="col-md-12">
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">

                            <input
                              checked={this.state.is_withdrawal == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_withdrawal"
                              type="radio" className="form-check-input"
                              id="user-update-is_withdrawal"
                            />Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_withdrawal == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_withdrawal"
                              id="user-update-is_withdrawal"
                              type="radio" className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="col-md-12">
                        <label htmlFor="is_trade">Trade</label>
                      </div>
                      <div className="col-md-12">
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">

                            <input
                              checked={this.state.is_trade == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_trade"
                              type="radio" className="form-check-input"
                              id="user-update-is_trade"
                            />Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_trade == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_trade"
                              id="user-update-is_trade"
                              type="radio" className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                  <div className='col-md-4'>
                      <div className="col-md-12">
                        <label htmlFor="is_show">Is show</label>
                      </div>
                      <div className="col-md-12">
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">

                            <input
                              checked={this.state.is_show == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_show"
                              type="radio" className="form-check-input"
                              id="user-update-is_show"
                            />Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_show == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_show"
                              id="user-update-is_show"
                              type="radio" className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="col-md-12">
                        <label htmlFor="is_show">Tabs</label>
                      </div>
                      <div className="col-md-12">
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">

                            <input
                              checked={this.state.is_tab == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_tab"
                              type="radio" className="form-check-input"
                              id="user-update-is_tab"
                            />Enabled
                          </label>
                        </div>
                        {this.state.short_name=="USDT"?"":<div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_tab == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_tab"
                              id="user-update-is_tab"
                              type="radio" className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>}
                        
                      </div>
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CryptoUpdateModal.propTypes = {
  updateCrypto: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateCrypto }
)(withRouter(CryptoUpdateModal));
