import { fas } from '@fortawesome/free-solid-svg-icons'
import React,{useState} from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function AddBlogModal(props) {
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [imageErr, setImageErr] = useState("")
    const handleChange=(e)=>{
        const {name,value}=e.target
        if(name==="date"){
            setDate(value)
            if(value===""){
                setDateErr("This field is required")
                return false
            }
            setDateErr("")
        }
        if(name==="title"){
            setTitle(value)
            if(value==""){
                setTitleErr("This field is required")
                return false
            }
            setTitleErr("")
        }
        // if(name==="description"){
        //     setDescription(value)
        //     if(value===""){
        //         setDescriptionErr("This field is required")
        //         return false
        //     }
        //     setDescriptionErr("")
        // }
    }
    const handleImage = (e) => {
        let { value } = e.target;
    
        const icon = e.target.files[0];
    
        if (value !== "" || value !== undefined) {
          setImage(e.target.files[0]);
          setImageUrl(URL.createObjectURL(e.target.files[0]));
          setImageErr("");
        } else {
          setImage(e.target.files[0]);
          setImageUrl("");
          setImageErr("This field is required");
        }
        if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
          setImageErr("Select valid image format");
          return false;
        }
      };
     const handleSubmit=(e)=>{
      e.preventDefault()
      if(date===""){
        setDateErr("This field is required")
        return false
      }
      if(title===""){
        setTitleErr("This field is required")
        return false
      }
      if(description===""){
        setDescriptionErr("This field is required")
        return false
      }
      if(image===""){
        setImageErr("This field is required")
        return false
      }
      let formdata=new FormData
      formdata.append("date",date)
      formdata.append("title",title)
      formdata.append("description",description)
      formdata.append("image",image)
      axios.post(baseUrl+"/api/blog-add",formdata).then((res)=>{
             if(res.data.status){
              props.setRefresh(!props.refresh)
              $('#add-user-modal').modal('hide');
              setDate("")
              setTitle("")
              setDescription("")
              setImage("")
              setImageUrl("")
             }
      }).catch((err)=>{

      })
     } 
  return (
    <div>
    <div className="modal fade" id="add-user-modal">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Blog</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <form  id="update-user" onSubmit={handleSubmit}>
                      <div className='mb-3'>
                        <label className='form-label'>Date:</label>
                         <input type="date" name="date" value={date} onChange={handleChange}  className='form-control'/>
                         <span style={{color:"red"}}>{dateErr}</span>
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>Title:</label>
                         <textarea name="title" value={title} onChange={handleChange}  className='form-control'/>
                         <span style={{color:"red"}}>{titleErr}</span>
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>Description:</label>
                        <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                        ],
                        heading: {
                          options: [
                            {
                              model: "paragraph",
                              title: "Paragraph",
                              class: "ck-heading_paragraph",
                            },
                            {
                              model: "heading1",
                              view: "h1",
                              title: "Heading 1",
                              class: "ck-heading_heading1",
                            },
                            {
                              model: "heading2",
                              view: "h2",
                              title: "Heading 2",
                              class: "ck-heading_heading2",
                            },
                            {
                              model: "heading3",
                              view: "h3",
                              title: "Heading 3",
                              class: "ck-heading_heading3",
                            },
                          ],
                        },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                         {/* <textarea name="description" value={description} onChange={handleChange}  className='form-control'/> */}
                         <span style={{color:"red"}}>{descriptionErr}</span>
                      </div>
                      <div className='mb-3'>
                      <img
                  style={{ width: "100px" }}
                  src={imageUrl}
                  className="img-fluid"
                  alt=""
                />
                        <label className='form-label'>Image:</label>
                         <input type="file" name="image"  onChange={handleImage}  className='form-control' accept="image/png, image/jpeg, image/jpg"/>
                         <span style={{color:"red"}}>{imageErr}</span>
                      </div>
                  

                      
                   
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button
                        form="update-user"
                        type="submit"
                        className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default AddBlogModal
