import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import CryptoAddModal from "../partials/CryptoAddModal";
import CryptoUpdateModal from "../partials/CryptoUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import $ from "jquery";
import { clearCryptoData } from "../../actions/cryptoActions";
import { baseUrl } from "../../actions/baseUrl";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function Crypto({ auth, cryptodata }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [records, setRecords] = useState([]);
  const [cryptoCheckedBoxes, setCryptoCheckedBoxes] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({
    id: "",
    name: "",
    short_name: "",
    order_sequence: "",
    available_for_loan: "",
    available_for_p2p: "",
    icon: "",
  });

  useEffect(() => {
    if (
      auth.user.cryptoCurrency !== undefined &&
      auth.user.cryptoCurrency !== 1
    ) {
      history.push("/dashboard");
    }
    getData();
  }, []);

  useEffect(() => {
    if (
      cryptodata !== undefined &&
      cryptodata.crypto !== undefined &&
      cryptodata.crypto.data !== undefined &&
      cryptodata.crypto.data.message !== undefined
    ) {
      $("#update-user-modal").modal("hide");
      $("#add-user-modal").modal("hide");
      toast(cryptodata.crypto.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(clearCryptoData());  // Clear the data after use
      // Set errors here, if needed.
    }
    getData();
  }, [cryptodata,dispatch]);

  const getData = () => {
    axios
      .get(baseUrl + "/api/crypto-data")
      .then((res) => {
        if (res.status === 200) {
          setRecords(res.data);
        }
      })
      .catch();
  };

  const editRecord = (record) => {
    setCurrentRecord({
      id: record.id,
      name: record.name,
      short_name: record.short_name,
      chain: record.chain_type,
      url: record.coin_tron,
      order_sequence: record.order_sequence,
      icon: record.icon,
      is_deposit: record.is_deposit,
      is_withdrawal: record.is_withdrawal,
      is_trade: record.is_trade,
      is_show: record.is_show,
      is_tab: record.is_tab,
    });
  };

  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/api/crypto-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    getData();
  };
  const columns = [
    {
      key: "id",
      text: "#",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "short_name",
      align: "left",
      sortable: true,
    },
    {
      key: "is_deposit",
      text: "Deposit",
      className: "is_deposit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.is_deposit == "1" ? "Enabled" : "Suspended"}</>;
      },
    },
    {
      key: "is_withdrawal",
      text: "Withdrawal",
      className: "is_withdrawal",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.is_withdrawal == "1" ? "Enabled" : "Suspended"}</>;
      },
    },
    {
      key: "is_trade",
      text: "Trade",
      className: "is_trade",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.is_trade == "1" ? "Enabled" : "Suspended"}</>;
      },
    },
    {
      key: "is_show",
      text: "Is show",
      className: "is_trade",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.is_show == "1" ? "Enabled" : "Suspended"}</>;
      },
    },
    {
      key: "is_tab",
      text: "Is Tab",
      className: "is_trade",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.is_tab == "1" ? "Enabled" : "Suspended"}</>;
      },
    },
    // {
    //     key: "order_sequence",
    //     text: "Sequence",
    //     className: "order_sequence",
    //     align: "left",
    //     sortable: true,
    // },
    {
      key: "created",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{Moment(record.created).format("lll")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
            {/* <button
                        className="btn btn-danger btn-sm"
                        onClick={() => this.deleteRecordMgs(record)}>
                        <i className="fa fa-trash"></i>
                    </button> */}
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Crypto Currency List",
    no_data_text: "No record found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <CryptoAddModal />
        <CryptoUpdateModal currentRecord={currentRecord} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mb-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} /> Add
            </button>
          
            <h4 className="mt-2 man_hadding mb-3">Crypto Currency List</h4>
            <div className="white_box">
            <button
              className="btn btn-primary float-right mb-3 mr-2"
              data-toggle="modal"
              data-target="#add-user-modal"
            >
              <FontAwesomeIcon icon={faPlus} />
              Add
            </button>
            <ReactDatatable
              config={config}
              records={records}
              columns={columns}
              onPageChange={pageChange}
            />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

Crypto.propTypes = {
  auth: PropTypes.object.isRequired,
  cryptodata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cryptodata: state.cryptodata,
  records: state.records,
});

export default connect(mapStateToProps)(Crypto);
