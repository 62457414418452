import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import AdminWithdrawalModal from "../partials/AdminWithdrawalModal";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import $ from "jquery";
import { baseUrl } from "./baseUrl";
import styled, { css } from "styled-components";
import UserFund from "../partials/UserFund";
import UserReferModal from "../partials/UserReferModal";
import UserWithdrawalModal from "../partials/UserWithdrawalModal";
import UserDepositeModal from "../partials/UserDepositeModal";
import UserTransferModal from "../partials/UserTransferModel";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  ${"" /* top: 0; */}
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
class WithdrawalLiveBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      records: [],
      set_url_token: "",
      currency: "",
      trx: 1,
    };

    const Url = window.location.pathname.split("/");
    if (Url[2]) {
      this.getData = this.getData.bind(this, Url[2]);
      this.state = { currency: Url[2] };
    } else {
      this.getData = this.getData.bind(this, "");
    }

    this.columns = [
      {
        key: "id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "email",
        text: "User Name",
        className: "firstName",
        align: "left",
        sortable: true,
        cell: (record, index) => {
          return (
            <span>
              {record.email === null ? record.mobile_no : record.email}
            </span>
          );
        },
      },
      {
        key: "eth_wallet_address",
        text: this.state.currency + " Wallet Address",
        className: "eth_wallet_address",
        align: "left",
        sortable: true,
      },

      {
        key: "",
        text: "User Portfolio",
        className: "eth_wallet_address",
        align: "left",
        sortable: true,
        cell: (record, index) => {
          return (
            <button
              data-toggle="modal"
              data-target="#update-fund-modal"
              className="btn btn-primary btn-sm mr-2"
              onClick={() => this.fundRecord(record)}
              title="User Fund"
            >
              User Portfolio
            </button>
          );
        },
      },
      {
        key: "",
        text: "Balance",
        className: "eth_wallet_address",
        align: "left",
        sortable: true,
        cell: (record, index) => {
          return <span id={"bal" + index}>{/* {this.state.trx} */}</span>;
        },
      },
      {
        key: "",
        text: this.state.currency + " Total Balance",
        className: "eth_total_balance",
        align: "left",
        sortable: true,
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() =>
                  this.getLiveBalance(record.eth_wallet_address, "bal" + index)
                }
                style={{ marginRight: "5px" }}
              >
                Get Live Balance
              </button>
            </Fragment>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {/* {this.state.liveBalance > 0 ? */}
              <button
                id={"livebal" + index}
                data-toggle="modal"
                data-target="#update-user-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px", display: "none" }}
              >
                Withdrawal
              </button>
              {/* } */}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Withdrawals List",
      no_data_text: "No records found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };
  }

  componentDidMount() {
    if (
      this.props.auth.user.sellOrder !== undefined &&
      this.props.auth.user.sellOrder !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextprops", nextProps);
    if (
      nextProps.auth.user.sellOrder !== undefined &&
      nextProps.auth.user.sellOrder !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (
      nextProps.withdrawal !== undefined &&
      nextProps.withdrawal.withdrawal !== undefined &&
      nextProps.withdrawal.withdrawal.data !== undefined &&
      nextProps.withdrawal.withdrawal.data.amount !== undefined
    ) {
      $("#update-user-modal").modal("hide");
      // $('#add-user-modal').modal('hide');
      toast(nextProps.withdrawal.withdrawal.data.amount, {
        position: toast.POSITION.TOP_CENTER,
      });
      // this.setState({ errors: {currencyImage:''}});
    }
    this.getData();
  }

  getData(token) {
    // this.setState({ isLoaderShow: true });
    // this.state = { isLoading: true };

    axios
      .post(baseUrl + "/api/get-all-withdrawals", { token: token })
      .then((res) => {
        if (res) {
          this.setState({ records: res.data, isLoaderShow: false });
        }
      })
      .catch();
  }

  fundRecord(record) {
    this.setState({
      user: record.id,
    });
    let data = {
      userId: record.id,
    };
    axios
      .post(baseUrl + "/frontapi/wallet_Coin_Total_Balance_admin", data)
      .then((res) => {
        this.setState({ walletRecord: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/wallet_Total_Balance_admin", data)
      .then((res) => {
        var resd = res.data;
        this.setState({ portfolioRecord: resd.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/user-refer-history-by-id", data)
      .then((res) => {
        this.setState({ referHistory: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/user-withdraw-history-by-id", data)
      .then((res) => {
        console.log("withrawHistory", res.data);
        this.setState({ withrawHistory: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/user-deposite-history-by-id", data)
      .then((res) => {
        console.log("withrawHistory", res.data);
        this.setState({ depositeHistory: res.data });
      })
      .catch();

    axios
      .post(baseUrl + "/frontapi/user-transfer-history-by-id", data)
      .then((res) => {
        console.log("withrawHistory", res.data);
        this.setState({ transferHistory: res.data });
      })
      .catch();
  }

  editRecord(record) {
    console.log("record", record);
    this.setState({ currentRecord: record });
  }

  getLiveBalance = (walletAddress, index) => {
    var postFields = {
      wallet_address: walletAddress,
      visible: true,
      coinName: this.state.currency,
    };
    if (this.state.currency == "TRX") {
      axios.post(baseUrl + "/api/gettrxbalance", postFields).then((respadd) => {
        // console.log("respadd", respadd);
        this.setState({ liveBalance: respadd.data.balance });
        if (respadd) {
          if (respadd.data.balance == 0) {
            toast.error("This user dont have balance");
            $("#live" + index).hide();
          }
          if (respadd.data.balance > 0) {
            var balance = respadd.data.balance;
            $("#" + index).text(balance);
            $("#live" + index).show();
            this.setState({ liveBalance: balance });
          }
        }
      });
    }

    if (this.state.currency == "ETH") {
      axios.post(baseUrl + "/api/getethbalance", postFields).then((respadd) => {
        // console.log("respadd", respadd);
        this.setState({ liveBalance: respadd.data.balance });
        if (respadd) {
          if (respadd.data.balance == 0) {
            toast.error("This user dont have balance");
            $("#live" + index).hide();
          }
          if (respadd.data.balance > 0) {
            var balance = respadd.data.balance;
            $("#" + index).text(balance);
            $("#live" + index).show();
            this.setState({ liveBalance: balance });
          }
        }
      });
    }

    if (this.state.currency == "SOL") {
      axios.post(baseUrl + "/api/getsolbalance", postFields).then((respadd) => {
        // console.log("respadd", respadd);
        this.setState({ liveBalance: respadd.data.balance });
        if (respadd) {
          if (respadd.data.balance == 0) {
            toast.error("This user dont have balance");
            $("#live" + index).hide();
          }
          if (respadd.data.balance > 0) {
            var balance = respadd.data.balance;
            $("#" + index).text(balance);
            $("#live" + index).show();
            this.setState({ liveBalance: balance });
          }
        }
      });
    }

    if (
      this.state.currency == "DBI" ||
      this.state.currency == "USDT" ||
      this.state.currency == "DOT" ||
      this.state.currency == "DOGE" ||
      this.state.currency == "XRP" ||
      this.state.currency == "ADA" ||
      this.state.currency == "ATOM" ||
      this.state.currency == "VET" ||
      this.state.currency == "BTTC" ||
      this.state.currency === "MATIC" ||
      this.state.currency === "ETH"
    ) {
      axios
        .post(baseUrl + "/api/get_pex_token_balance", postFields)
        .then((respadd) => {
          // console.log("respadd", respadd);
          this.setState({
            liveBalance: respadd.data.data[this.state.currency],
          });
          if (respadd) {
            if (respadd.data.data[this.state.currency] == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data[this.state.currency] > 0) {
              var balance = respadd.data.data[this.state.currency];
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }

    if (this.state.currency == "BNB") {
      axios
        .post(baseUrl + "/api/get_bnb_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance / 1000000000000000000;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    // if (this.state.currency == "XRP") {
    //   axios
    //     .post(baseUrl + "/api/get_xrp_token_balance", postFields)
    //     .then((respadd) => {
    //       console.log("respadd", respadd);
    //       this.setState({ liveBalance: respadd.data.data.balance });
    //       if (respadd) {
    //         if (respadd.data.data.balance == 0) {
    //           toast.error("This user dont have balance");
    //           $("#live" + index).hide();
    //         }
    //         if (respadd.data.data.balance > 0) {
    //           var balance = respadd.data.data.balance;
    //           $("#" + index).text(balance);
    //           $("#live" + index).show();
    //           this.setState({ liveBalance: balance });
    //         }
    //       }
    //     });
    // }
    if (this.state.currency == "BTT") {
      axios
        .post(baseUrl + "/api/get_btt_token_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    if (this.state.currency == "LTC") {
      axios
        .post(baseUrl + "/api/get_ltc_token_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    // if (this.state.currency == "DOGE") {
    //   axios
    //     .post(baseUrl + "/api/get_doge_token_balance", postFields)
    //     .then((respadd) => {
    //       console.log("respadd", respadd);
    //       this.setState({ liveBalance: respadd.data.data.balance });
    //       if (respadd) {
    //         if (respadd.data.data.balance == 0) {
    //           toast.error("This user dont have balance");
    //           $("#live" + index).hide();
    //         }
    //         if (respadd.data.data.balance > 0) {
    //           var balance = respadd.data.data.balance;
    //           $("#" + index).text(balance);
    //           $("#live" + index).show();
    //           this.setState({ liveBalance: balance });
    //         }
    //       }
    //     });
    // }
    if (this.state.currency == "SHIB") {
      axios
        .post(baseUrl + "/api/get_shib_token_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    if (this.state.currency == "WIN") {
      axios
        .post(baseUrl + "/api/get_win_token_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    if (this.state.currency == "1INCH") {
      axios
        .post(baseUrl + "/api/get_1inch_token_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    if (this.state.currency == "GALA") {
      axios
        .post(baseUrl + "/api/get_gala_token_balance", postFields)
        .then((respadd) => {
          console.log("respadd", respadd);
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }

    // if (this.state.currency == "DOT") {
    //   axios
    //     .post(baseUrl + "/api/get_dot_token_balance", postFields)
    //     .then((respadd) => {
    //       this.setState({ liveBalance: respadd.data.data.balance });
    //       if (respadd) {
    //         if (respadd.data.data.balance == 0) {
    //           toast.error("This user dont have balance");
    //           $("#live" + index).hide();
    //         }
    //         if (respadd.data.data.balance > 0) {
    //           var balance = respadd.data.data.balance;
    //           $("#" + index).text(balance);
    //           $("#live" + index).show();
    //           this.setState({ liveBalance: balance });
    //         }
    //       }
    //     });
    // }
    if (this.state.currency == "SUSHI") {
      axios
        .post(baseUrl + "/api/get_sushi_token_balance", postFields)
        .then((respadd) => {
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    if (this.state.currency == "BabyDoge") {
      axios
        .post(baseUrl + "/api/get_babydog_token_balance", postFields)
        .then((respadd) => {
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
    if (this.state.currency == "FLOKI") {
      axios
        .post(baseUrl + "/api/get_floki_token_balance", postFields)
        .then((respadd) => {
          this.setState({ liveBalance: respadd.data.data.balance });
          if (respadd) {
            if (respadd.data.data.balance == 0) {
              toast.error("This user dont have balance");
              $("#live" + index).hide();
            }
            if (respadd.data.data.balance > 0) {
              var balance = respadd.data.data.balance;
              $("#" + index).text(balance);
              $("#live" + index).show();
              this.setState({ liveBalance: balance });
            }
          }
        });
    }
  };

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <AdminWithdrawalModal
            record={this.state.currentRecord}
            liveBalance={this.state.liveBalance}
            currency={this.state.currency}
          />

          <UserFund
            currentRecord={this.state.walletRecord}
            user={this.state.user}
            portfolioRecord={this.state.portfolioRecord}
          />
          <UserReferModal referHistory={this.state.referHistory} />
          <UserWithdrawalModal withrawHistory={this.state.withrawHistory} />
          <UserDepositeModal depositeHistory={this.state.depositeHistory} />
          <UserTransferModal transferHistory={this.state.transferHistory} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>

              <h4 className="mt-2 man_hadding mb-3">
                {this.state.currency} Withdrawals
              </h4>
              <DarkBackground disappear={this.state.isLoaderShow == true}>
                <LoadingOverlay
                  active={true}
                  spinner={true}
                  text="Loading your content..."
                >
                  {/* <p>Some content or children or something.</p> */}
                </LoadingOverlay>
              </DarkBackground>
              <div className="white_box">
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  onPageChange={this.pageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

WithdrawalLiveBalance.propTypes = {
  auth: PropTypes.object.isRequired,
  withdrawal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
  withdrawal: state.withdrawal,
});

export default connect(mapStateToProps)(WithdrawalLiveBalance);
