import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CoinWithdrawalReject from "../partials/CoinWithdrawalReject";
import CoinWithdrawalApprove from "../partials/CoinWithdrawalApprove";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import { baseUrl } from "./baseUrl";

class CoinWithdrawal extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "_id",
        text: "Transaction_id",
        className: "transaction_id",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <span>{record.tx_id}</span>;
        },
      },
      {
        key: "wallet_address",
        text: "Wallet Address",
        className: "wallet_address",
        align: "left",
        sortable: true,
        cell: (record) => {
          const record1 = record.wallet_address;
          // console.log(record1.slice(5))
          if (record1) {
            record1.slice(5);
            return (
              <span className="wallet_a_box">
                {record1.slice(0, 6)}
                <i
                  className="fa fa-clone"
                  onClick={() => {
                    navigator.clipboard.writeText(record.wallet_address);
                    toast.success("Copied");
                  }}
                ></i>
              </span>
            );
          } else {
            return <span>{record.wallet_address}</span>;
          }
        },
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "mobile",
        text: "Mobile No.",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "name",
        text: "Coin Type",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "amount",
        text: "Requested",
        className: "amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <span>{record.coin_amount}</span>;
        },
      },
      {
        key: "withdrawal_fees",
        text: "Withdrawal Fees",
        className: "amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          var type = record.type;
          return (
            <span>
              {record.withdrawal_fees == null
                ? "Not Avaliable"
                : parseFloat(record.withdrawal_fees)}{" "}
              {/* {type == "flat" ? "Flat" : type == "percentage" ? "%" : ""} */}
            </span>
          );
        },
      },
      {
        key: "",
        text: "Recived",
        className: "amount",
        align: "left",
        sortable: true,
        cell: (record) => {
          var type = record.type;
          // var feeValue =
          // (parseFloat(record.coin_amount) *
          //   parseFloat(record.withdrawal_fees)) /
          // 100;
        var withdrawalFee = parseFloat(record.coin_amount) - record.withdrawal_fees;
          // if (type == "percentage") {
          //   var feeValue =
          //     (parseFloat(record.coin_amount) *
          //       parseFloat(record.withdrawal_fees)) /
          //     100;
          //   var withdrawalFee = parseFloat(record.coin_amount) - feeValue;
          // }
          // if (type == "flat") {
          //   withdrawalFee =
          //     parseFloat(record.coin_amount) -
          //     parseFloat(record.withdrawal_fees);
          // }
          return (
            <span>
              {record.withdrawal_fees != null
                ? withdrawalFee
                : record.coin_amount}
            </span>
          );
        },
      },
      // {
      //   key: "",
      //   text: "Fees Deducted",
      //   className: "",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     var type = record.type;
      //     var feeValue =
      //     (parseFloat(record.coin_amount) *
      //       parseFloat(record.withdrawal_fees)) /
      //     100;
      //     // if (type == "percentage") {
      //     //   var feeValue =
      //     //     (parseFloat(record.coin_amount) *
      //     //       parseFloat(record.withdrawal_fees)) /
      //     //     100;
      //     // }
      //     return (
      //       <span>
      //         {feeValue
      //           ? parseFloat(feeValue)
      //           : record.withdrawal_fees == null
      //           ? "Not Avaliable"
      //           : parseFloat(record.withdrawal_fees)}
      //       </span>
      //     );
      //   },
      // },
      {
        key: "tx_hash",
        text: "Transaction Hash",
        className: "status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <span>{record.tx_hash}</span>;
        },
      },
      {
        key: "created",
        text: "Date",
        className: "created",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.created).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <p className="mb-0">
                {/* {alert(record.WithdrawlRequest)}
                 */}
                 {record.admin_approve=='1'? record.WithdrawlRequest == "0" ? (
                  <>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-approve"
                      className="btn btn-success btn-sm"
                      onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-reject"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </>
                ) : record.WithdrawlRequest == "1" ? (
                  <button
                    // data-toggle="modal"
                    // data-target="#update-user-modal-approve"
                    className="btn btn-success action-btn"
                    onClick={() => this.editRecord(record)}
                    style={{ marginRight: "5px" }}
                  >
                    <span>
                      Approved <i className="fa fa-check"></i>
                    </span>
                  </button>
                ) : (
                  <>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-reject"
                      className="btn btn-danger action-btn"
                      onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      Rejected <i className="fa fa-close"></i>
                    </button>{" "}
                  </>
                ):record.WithdrawlRequest == "2"? <>
                <button
                  data-toggle="modal"
                  data-target="#update-user-modal-reject"
                  className="btn btn-danger action-btn"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  Rejected <i className="fa fa-close"></i>
                </button>{" "}
              </>:<><button
                  className="btn btn-primary action-btn"
                  style={{ marginRight: "5px" }}
                >
                  Pending from user 
                </button>
                </>
                }
               
              </p>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Fund Request List",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
    };

    this.state = {
      currentRecord: {
        id: "",
        coin_amount: "",
        user_id: "",
        approve: "",
        reject: "",
        status: "",
      },
    };

    this.getData = this.getData.bind(this);
  }
  copyCodeToClipboard = () => {
    const el = this.Copy;
    el.select();
    document.execCommand("copy");
    this.setState({ copySuccess: true });
  };
  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    this.getData();
  }

  getData() {
    axios
      .get(baseUrl + "/api/userCoinwithdrawalHistory")
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  supportClick = (e) => {
    var html = [];

    this.state.records.map((value) => {
      console.log("valuevaluevaluevalue", value);
      if (e.target.value == "Pending") {
        if (value.WithdrawlRequest == "4") {
          html.push(value);
        }
      }
      if (e.target.value == "Approved") {
        if (value.WithdrawlRequest == "1") {
          html.push(value);
        }
      }
      if (e.target.value == "Reject") {
        if (
          value.WithdrawlRequest != "0" &&
          value.WithdrawlRequest != "1" &&
          value.WithdrawlRequest != "4"
        ) {
          html.push(value);
        }
      }
      if (e.target.value == "select") {
        html.push(value);
      }
    });
    this.setState({ FilterRecords: html });
  };

  showFilterList = () => {
    console.log("showqfilterlist");

    const html = [];

    html.push(
      <select
        className="form-control"
        id="exchange"
        name="supportFilter"
        onClick={this.supportClick}
      >
        <option value="select" id="">
          select
        </option>
        <option value="Pending">Pending</option>
        <option value="Reject">Reject</option>
        <option value="Approved">Approved</option>
      </select>
    );

    return <div className="support-select">{html}</div>;
  };

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <CoinWithdrawalReject record={this.state.currentRecord} />
          <CoinWithdrawalApprove record={this.state.currentRecord} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <h4 className="mt-2 man_hadding mb-3">
                Withdrawal Coin Request List
              </h4>
              <div className="white_box">

              {this.showFilterList()}
              <div className="select_change_r">
                <div className="scrollmenu coin_w">
                  <ReactDatatable
                    config={this.config}
                    records={
                      this.state.FilterRecords
                        ? this.state.FilterRecords
                        : this.state.records
                    }
                    columns={this.columns}
                    onPageChange={this.pageChange.bind(this)}
                    className="scrolling-table table table-bordered table-striped"
                  />
                </div>
              </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

CoinWithdrawal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(CoinWithdrawal);
