import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../pages/baseUrl";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cryptoList: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    axios
      .get(baseUrl + "/api/crypto-data")
      .then((res) => {
        if (res.status === 200) {
          this.setState({ cryptoList: res.data });
        }
      })
      .catch();
  };
  showHtml = () => {
    const html = [];
    this.state.cryptoList.map(function (value, i) {
      html.push(
        <>
          <a
            href={"/withdrawals/" + value.short_name}
            className="list-group-item list-group-item-action"
          >
            {value.short_name}
          </a>
        </>
      );
    });
    return html;
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;
    console.log("user", user);
    var pathname = this.props.location.pathname;

    return (
      <div className="left-menu-outer" id="navbarSupportedContent">
        <div id="sidebar-wrapper">
          <div className="navbar-brand" href="/">
            <Link to="/dashboard">
              {" "}
              <img src="/img/logo.png" alt="" />
            </Link>
          </div>
          <div className="list-group list-group-flush">
            <Link
              to="/dashboard"
              className={
                this.props.location.pathname == "/dashboard"
                  ? "list-group-item list-group-item-action active"
                  : "list-group-item list-group-item-action"
              }
            >
              <img src="/img/dash-icon.png" alt="" /> Dashboard
            </Link>

            {
              user.cryptoCurrency == 1 && (
                <div className="">
                  <a
                    type="button"
                    className="list-group-item list-group-item-action collapsed"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    data-target="#demo1"
                  >
                    <img src="/img/coin-manage-icon.png" alt="" /> Currency
                    Manage <i className="fa fa-angle-down d5"></i>
                  </a>
                  <div
                    id="demo1"
                    className={
                      this.props.location.pathname == "/crypto_currency" ||
                      this.props.location.pathname == "/pair_management" ||
                      this.props.location.pathname == "/open-pair" ||
                      this.props.location.pathname == "/credentials"
                        ? "collapse drop_down show"
                        : "collapse drop_down"
                    }
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    {/* {user.cryptoCurrency == 1 && ()} */}
                    <Link
                      to="/crypto_currency"
                      className={
                        this.props.location.pathname == "/crypto_currency"
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                      }
                    >
                      Crypto Currency{" "}
                    </Link>

                    {/* {user.pairManagement == 1 && ()} */}
                    <Link
                      to="/pair_management"
                      className={
                        this.props.location.pathname == "/pair_management"
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                      }
                    >
                      Pair Manage{" "}
                    </Link>
                    <Link
                      to="/open-pair"
                      className={
                        this.props.location.pathname == "/open-pair"
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                      }
                    >
                      Default Pair{" "}
                    </Link>

                    <Link
                      to="/credentials"
                      className={
                        this.props.location.pathname == "/credentials"
                          ? "list-group-item list-group-item-action active"
                          : "list-group-item list-group-item-action"
                      }
                    >
                      Binance Pair Managment{" "}
                    </Link>
                  </div>
                </div>
              )
              //  : (
              //   ""
              // )
            }
            {user.intrestRate == 1 ? (
              <div className="">
                <a
                  type="button"
                  className="list-group-item list-group-item-action collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                  data-target="#demo3"
                >
                  <img src="/img/commission-icon.png" alt="" /> Commission
                  Manage <i className="fa fa-angle-down d5"></i>
                </a>
                <div
                  id="demo3"
                  className={
                    this.props.location.pathname == "/commission" ||
                    this.props.location.pathname == "/witdrawalManager" ||
                    this.props.location.pathname ==
                      "/withdrawal_fees-collection" ||
                    this.props.location.pathname == "/fee-collection" ||
                    this.props.location.pathname == "/sell_fee-collection"
                      ? "collapse drop_down show"
                      : "collapse drop_down"
                  }
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  {/* {user.roleManager == 1 && ()} */}
                  <Link
                    to="/commission"
                    className={
                      this.props.location.pathname == "/commission"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Trade fee
                  </Link>

                  {/* {user.roleManager == 1 && ()} */}
                  <Link
                    to="/witdrawalManager"
                    className={
                      this.props.location.pathname == "/witdrawalManager"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Withdrawal fee
                  </Link>

                  {/* {user.roleManager == 1 && ( )} */}
                  <Link
                    to="/withdrawal_fees-collection"
                    className={
                      this.props.location.pathname ==
                      "/withdrawal_fees-collection"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Withdrawal fees collection
                  </Link>

                  {/* {user.roleManager == 1 && ()} */}
                  <Link
                    to="/fee-collection"
                    className={
                      this.props.location.pathname == "/fee-collection"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Buy fees collection
                  </Link>

                  {/* {user.roleManager == 1 && ()} */}
                  <Link
                    to="/sell_fee-collection"
                    className={
                      this.props.location.pathname == "/sell_fee-collection"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Sell fees collection
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
            {user.manageUsers == 1 && (
              <Link
                to="/users"
                className={
                  this.props.location.pathname == "/users"
                    ? "list-group-item list-group-item-action active"
                    : "list-group-item list-group-item-action"
                }
              >
                <img src="/img/manage-user-icon.png" alt="" /> Manage Users
              </Link>
            )}
            {/* <Link
                    to="/user-data"
                    className={this.props.location.pathname == "/user-data" ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
                  >
                    <img src="/img/user-data-icon.png" /> User Data Management
                  </Link> */}
            {/* <Link
                    to="/refer-deposit-list"
                    className={this.props.location.pathname == "/refer-deposit-list" ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
                  >
                    <img src="/img/user-data-icon.png" /> Refrral Deposit Request
                  </Link> */}

            {/* {user.botManagement == 1 ? (
              <Link
                to="/refralmanager"
                className={
                  this.props.location.pathname == "/refralmanager"
                    ? "list-group-item list-group-item-action active"
                    : "list-group-item list-group-item-action"
                }
              >
                <img src="/img/reffer-icon.png" /> Referral Manage
              </Link>
            ) : (
              ""
            )} */}

            {user.verifyComplete == 1 ? (
              <div className="">
                <a
                  type="button"
                  className="list-group-item list-group-item-action collapsed"
                  data-toggle="collapse"
                  data-target="#demo2"
                  aria-expanded="false"
                  aria-controls="collapseOne2"
                >
                  <img src="/img/kyc-manage-icon.png" alt="" /> KYC Manage{" "}
                  <i className="fa fa-angle-down d5"></i>
                </a>
                <div
                  id="demo2"
                  className={
                    this.props.location.pathname == "/users-complite-record" ||
                    this.props.location.pathname == "/Users-pending-record"
                      ? "collapse drop_down show"
                      : "collapse drop_down"
                  }
                  aria-labelledby="headingOne2"
                  data-parent="#accordionExample"
                >
                  <Link
                    to="/users-complite-record"
                    className={
                      this.props.location.pathname == "/users-complite-record"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    <img src="/img/kyc-complete-icon.png" alt="" /> Complete KYC
                  </Link>

                  {/* {user.verifyPending == 1 && ()} */}
                  <Link
                    to="/Users-pending-record"
                    className={
                      this.props.location.pathname == "/Users-pending-record"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    <img src="/img/kyc-pending-icon.png" alt="" /> Pending KYC
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}

            {user.roleManager == 1 ? (
              <div className="">
                <a
                  type="button"
                  className="list-group-item list-group-item-action collapsed"
                  data-toggle="collapse"
                  data-target="#demo8"
                  aria-expanded="false"
                  aria-controls="collapseOne2"
                >
                  <img src="/img/deposit-manage-icon.png" alt="" /> Deposit
                  Manage <i className="fa fa-angle-down d5"></i>
                </a>
                <div
                  id="demo8"
                  className={
                    this.props.location.pathname == "/UserDepositHistory" ||
                    this.props.location.pathname == "/deposit-inr"
                      ? "collapse drop_down show"
                      : "collapse drop_down"
                  }
                  aria-labelledby="headingOne2"
                  data-parent="#accordionExample"
                >
                  {/* {user.roleManager == 1 && ( )} */}
                  <Link
                    to="/UserDepositHistory"
                    className={
                      this.props.location.pathname == "/UserDepositHistory"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Coin Deposit
                  </Link>

                  {/* {user.roleManager == 1 && (
                  <Link
                    to="/deposit-inr"
                    className={
                      this.props.location.pathname == "/deposit-inr"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    INR Deposit
                  </Link>
                )} */}
                </div>
              </div>
            ) : (
              ""
            )}

            {user.fundRequest == 1 ? (
              <div className="">
                <a
                  type="button"
                  className="list-group-item list-group-item-action collapsed"
                  data-toggle="collapse"
                  data-target="#demo4"
                  aria-expanded="false"
                  aria-controls="collapseOne2"
                >
                  <img src="/img/withdrawal-manage-icon.png" alt="" />{" "}
                  Withdrawal Manage <i className="fa fa-angle-down d5"></i>
                </a>
                <div
                  id="demo4"
                  className={
                    this.props.location.pathname == "/coinWithdrawal" ||
                    this.props.location.pathname == "/withdrawal-inr"
                      ? "collapse drop_down show"
                      : "collapse drop_down"
                  }
                  aria-labelledby="headingOne2"
                  data-parent="#accordionExample"
                >
                  {/* {user.roleManager == 1 && ( )} */}
                  <Link
                    to="/coinWithdrawal"
                    className={
                      this.props.location.pathname == "/coinWithdrawal"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Coin Withdrawal
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
            {user.buyOrder == 1 ? (
              <div className="">
                <a
                  type="button"
                  className="list-group-item list-group-item-action collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                  data-target="#demo5"
                >
                  <img src="/img/order-list-icon.png" alt="" /> Order List{" "}
                  <i className="fa fa-angle-down d5"></i>
                </a>
                <div
                  id="demo5"
                  className={
                    this.props.location.pathname == "/sell-order" ||
                    this.props.location.pathname == "/buy-order"
                      ? "collapse drop_down show"
                      : "collapse drop_down"
                  }
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  {/* {user.sellOrder == 1 && ()} */}
                  <Link
                    to="/sell-order"
                    className={
                      this.props.location.pathname == "/sell-order"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Sell Order{" "}
                  </Link>
                  <Link
                    to="/buy-order"
                    className={
                      this.props.location.pathname == "/buy-order"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }
                  >
                    Buy Order{" "}
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
            <div id="accordionExample">
              {user.liquidations == 1 && (
                <div className="">
                  <a
                    type="button"
                    className="list-group-item list-group-item-action collapsed"
                    data-toggle="collapse"
                    data-target="#demowith"
                  >
                    <img src="/img/live-account-icon.png" alt="" /> Live Account
                    Balance <i className="fa fa-angle-down d5"></i>
                  </a>
                  <div
                    id="demowith"
                    className={
                      pathname.includes("withdrawals") == true
                        ? "drop_down collapse show"
                        : "drop_down collapse"
                    }
                  >
                    {this.showHtml()}
                  </div>
                </div>
              )}
            </div>

            {user.roleManager == 1 && (
              <Link
                to="/role-manager"
                className={
                  this.props.location.pathname == "/role-manager"
                    ? "list-group-item list-group-item-action active"
                    : "list-group-item list-group-item-action"
                }
              >
                <img src="/img/role-manage-icon.png" alt="" /> Role Manage &#36;
              </Link>
            )}

            {user.suportManagement == 1 && (
              <Link
                to="/suport"
                className={
                  this.props.location.pathname == "/suport"
                    ? "list-group-item list-group-item-action active"
                    : "list-group-item list-group-item-action"
                }
              >
                <img src="/img/supports-icon.png" alt="" /> Support Manage{" "}
              </Link>
            )}

            {user.suportManagement == 1 && (
              <Link
                to="/liquidity-management"
                className={
                  this.props.location.pathname == "/liquidity-management"
                    ? "list-group-item list-group-item-action active"
                    : "list-group-item list-group-item-action"
                }
              >
                <img src="/img/liquidity_manager.png" alt="" /> Liquidity
                Manager{" "}
              </Link>
            )}

            <Link
              to="/contact-us"
              className={
                this.props.location.pathname == "/contact-us"
                  ? "list-group-item list-group-item-action active"
                  : "list-group-item list-group-item-action"
              }
            >
              <img src="/img/contact-icon.png" alt="" /> Contact Us{" "}
            </Link>

            <Link
              to="/changePassword"
              className={
                this.props.location.pathname == "/changePassword"
                  ? "list-group-item list-group-item-action active"
                  : "list-group-item list-group-item-action"
              }
            >
              <img src="/img/padlock.png" alt="" />
              Change Password{" "}
            </Link>
            {user.loan == 1 && (
              <Link
                to="/blog"
                className={
                  this.props.location.pathname == "/blog"
                    ? "list-group-item list-group-item-action active"
                    : "list-group-item list-group-item-action"
                }
              >
                <img src="/img/blogging.png" alt="" />
                Blog{" "}
              </Link>
            )}

            {/* <div className="">
              <a
                type="button"
                className="list-group-item list-group-item-action collapsed"
                data-toggle="collapse"
                data-target="#demo3" aria-expanded="false" aria-controls="collapseOne3"
              >
                INR Transactions <i className="fa fa-angle-down d5"></i>
              </a>
              <div id="demo3" className={this.props.location.pathname == "/deposit-inr" || this.props.location.pathname == "/withdrawal-inr" || this.props.location.pathname == "/fund-request" || this.props.location.pathname == "/balance" ? "collapse drop_down show" : "collapse drop_down"} aria-labelledby="headingOne3" data-parent="#accordionExample">


                {user.fundRequest == 1 && (
                  <Link
                    to="/fund-request"
                    className={this.props.location.pathname == "/fund-request" ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
                  >
                    Transaction History
                  </Link>
                )}

                {user.roleManager == 1 && (
                  <Link
                    to="/balance"
                    className={this.props.location.pathname == "/balance" ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
                  >
                    Add/Deduct
                  </Link>
                )}




              </div>
            </div> */}

            {/* <div className="">
            <a
              type="button"
              className="list-group-item list-group-item-action collapsed"
              data-toggle="collapse"
              data-target="#demowith"
            >
              Live Account Balance <i className="fa fa-angle-down d5"></i>
            </a>
            <div id="demowith" className="collapse drop_down">
              {this.showHtml()}
            </div>
          </div>   */}

            {/* {user.fundRequest == 1 && (
            <Link
              to="/inr-request"
              className="list-group-item list-group-item-action"
            >
             Fund Request
            </Link>
          )} */}

            <button
              className="list-group-item list-group-item-action"
              onClick={this.onLogoutClick}
            >
              <img src="/img/logout-icon.png" alt="" />
              Logout
            </button>
            {/* <div className="collapse show">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link text-white" onClick={this.onLogoutClick} href="#"></a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Sidebar));
