import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addBanner} from "../../actions/bannerAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class BannerAddModel extends React.Component {

    constructor() {
        super();
        this.state = {
            name: "",
            short_name:"",
            order_sequence:"",
            errors: {},
            coinImage: '',
            is_deposit:0,
            is_withdrawal:0,
            is_trade:0,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
       
    }
    handleChange = (event) => {
        let { name, value } = event.target;
        console.log("valuefdvfdv",event.target)
        this.setState({ [name]: value, emptyField: false, errMsg: "" });
      };

    onChange = e => {

        if (e.target.name === 'currencyImage') {
            var currencyImage = e.target.files[0];
            
            var fileSize = e.target.files[0].size;
            if (fileSize > 1000000) {
                this.setState({ invalidImage: "Maximum 1 MB Image allowed.", userUploadImage: false });
                return false;
            }
            console.log(this.state.invalidImage);
            if (!currencyImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid image jpeg,png,gif.", userUploadImage: false });
                return false;
            }
        
            this.setState({
                coinImage: currencyImage,
            });
        }

        this.setState({ [e.target.id]: e.target.value });
    };

    onCryptoAdd = e => {
        e.preventDefault();
        console.log("YTest",this.state.chain)
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        
        const formData = new FormData();
        formData.append('currencyImage',this.state.coinImage);
        formData.append('name',this.state.name);
        this.props.addBanner(formData,config);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Banner Add Model</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCryptoAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Banner Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                name="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>

                                    
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Banner Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.currencyImage}
                                                id="currencyImage"
                                                name="currencyImage"
                                                type="file"
                                                error={errors.currencyImage}
                                                className={classnames("form-control", {
                                                    invalid: errors.currencyImage
                                                })}/>
                                                
                                            <span className="text-danger">{errors.currencyImage}</span>
                                        </div>
                                    </div>



                                   
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BannerAddModel.propTypes = {
    addBanner: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addBanner }

)(withRouter(BannerAddModel));