import React,{useState,useEffect} from 'react'
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import axios from "axios"
import AddBlogModal from '../partials/AddBlogModal';
import { toast, ToastContainer} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from './baseUrl';
import UpdateBlogModal from '../partials/UpdateBlogModal';
function Blog() {
   const [refresh, setRefresh] = useState(false)
   const [record, setRecord] = useState([])
    const [image, setImage] = useState("")
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState("")
    const [currentRecord, setCurrentRecord] = useState({})
   useEffect(() => {
    getData()
   }, [refresh])
    
   const getData=()=>{
    axios.get(baseUrl+"/api/get-blog").then((res)=>{
      if(res.data.status){
        setRecord(res.data.data)
      }
      else{
        setRecord([])
      }
    }).catch((err)=>{

    })
   }
   const columns = [
    {
      // key: "id",
      text: "#",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Created At",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {Moment.utc(record.created_at).local().format("lll")}
          </>
        );
      },
    },
    {
      key: "description",
      text: "Description",
      className: "description",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button className='btn btn-primary' data-toggle="modal" data-target="#view-user-modal" onClick={()=>{viewData(record)}}>View</button>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: record => {
          return (
              <>
                  <button
                      data-toggle="modal"
                      data-target="#update-user-modal"
                      className="btn btn-primary btn-sm"
                      onClick={() => editRecord(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteRecordMgs(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
              </>
          );
      }
  }
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Buy Order List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const deleteRecordMgs=(record)=>{
    confirmAlert({
        title: 'Confirm to submit',
        message: 'Really want to delete this blog?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {deleteRecord(record)}
          },
          {
            label: 'No'
          
          }
        ]
      });


}
 const deleteRecord=(record)=>{
    
    axios
        .post(baseUrl+"/api/delete-blog", {id: record.id})
        .then(res => {
            if (res.status === 200) {
              setRefresh(!refresh)
               toast(res.data.message, {
                   position: toast.POSITION.TOP_CENTER,
               })
              
            }
        })
        .catch();
   
}
const viewData=(record)=>{
  setTitle(record.title)
  setDescription(record.description)
  setImage(record.image)
}
 const editRecord = (record) => {

  setCurrentRecord({
      id:record.id,
      title:record.title,
      description:record.description,
      image:baseUrl+"/static/blog/"+record.image,
      date:record.date
      
  });
}

  return (
    <div>
    <Navbar />
    <div className="" id="wrapper">
      <Sidebar />

      <div id="page-content-wrapper">
        <div className="container-fluid">
          <button className="btn btn-link mt-3" id="menu-toggle">
            <FontAwesomeIcon icon={faList} />
          </button>  
   
           <AddBlogModal setRefresh={setRefresh} refresh={refresh}/>
           <UpdateBlogModal setRefresh={setRefresh} refresh={refresh} currentRecord={currentRecord}/>
          <h4 className="mt-2 man_hadding mb-3">Blogs</h4>
          <div className='white_box'>
          <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> Add </button>
          <ReactDatatable
            config={config}
            records={record}
            columns={columns}
           
          />
          </div>
          </div>
        
          <ToastContainer/>
        </div>
        <div className="modal fade" id="view-user-modal">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Blog</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                    <div>
                      <div className='row'>
                           <div className='col-md-6 mb-3'>
                             <img className="img-fluid" src={baseUrl+"/static/blog/"+image}/>
                           </div>
                           <div className='col-md-12'>
                             <h5>{title}</h5>
                             {/* <p>{description}</p> */}
                             <div dangerouslySetInnerHTML={{__html: description}}></div>
                             </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
   
    </div>

  )
}

export default Blog
